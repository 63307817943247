#blogDetail {
    .wrapper {
        padding: 120px 80px;

        @media (min-width: 992px) {
            .col-lg-9 {
                width: 74%;
            }
            .col-lg-3 {
                width: 26%;
                padding-inline: 0;
            }
        }
        @media (max-width: 991px) {
            padding: 58px 25px 60px;

            .row {
                flex-direction: column-reverse;
                gap: 32px;
            }
        }
        .posts {
            max-width: 870px;

            h5 {
                color: $accentColor;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px; 
                
                @media (max-width: 991px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            h2 {
                color: $black;
                font-size: 45px;
                font-weight: 300;
                line-height: 60px;
                margin-block: 20px 60px;

                @media (max-width: 991px) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }
            p {
                color: $neutral;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;

                strong { color: $accentColor; }
            }
            a {
                color: $accentColor;
                text-decoration: underline $accentColor;
            }
            figure {
                // display: flex;
                position: relative;
                margin: 0;

                @media (max-width: 991px) {
                    flex-direction: column;
                }
                // &::after {
                //     content: '';
                //     position: absolute;
                //     inset: 60% 0 0 0;
                //     background: linear-gradient(0deg, #000000 -16.72%, rgba(0, 0, 0, 0) 97.69%);
                //     mix-blend-mode: multiply;
                //     z-index: 1;
                // }
            }
            figcaption {
                color: $neutral;
                font-family: inherit;
                font-size: 15px;
                font-weight: 400;
                line-height: 23px;
                margin-top: 12px;

                display: inline-block;
                // position: absolute;
                // bottom: 17.9px;
                // left: 18px;
                // z-index: 2;

                @media (max-width: 991px) {
                    font-size: 14.5px;
                    line-height: 22px;
                }
            }
            img {
                border-radius: 20px 0 20px 0;
                flex-grow: 1;
                max-width: 100%;
                position: relative;
            }
            p + p { margin-top: 20px; }
            p + figure,
            figure + p { margin-top: 40px; }
            table {
                @media (max-width: 991px) {
                    tr {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                    }
                }
                &, tbody, tr, td { border: none; }
                td {
                    &:first-of-type {
                        figure { 
                            margin-right: 20px;

                            @media (max-width: 991px) {
                                margin-right: 0;
                                margin-bottom: 20px;
                            }
                        }
                    }
                    figure {
                        @media (max-width: 991px) {
                            min-width: 100%;
                            max-height: 821px;
                        }
                    }
                }
            }

            &--footer {
                margin-top: 44px;

                &-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-width: 991px) {
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;
                        gap: 20px;
                    }
                    h6 {
                        color: $black;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;

                        strong { 
                            font-family: inherit;
                            color: $accentColor; 
                            font-weight: 600;
                        }
                    }
                    .icon-text {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                    }
                    .socials {
                        img {
                            max-width: 24px;
                            aspect-ratio: 1;
                            border-radius: 0;
                        }
                    }
                }
                .tags {
                    margin-top: 32px;
                }
            }
        }
        .aside {
            @media (max-width: 991px) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                gap: 20px;
            }
            &-search {
                form {
                    @media (max-width: 991px) {
                        max-width: 54px;
                    }
                }
                .floating-label {
                    @media (max-width: 991px) {
                        max-height: 56px;
                    }
                    .form-control {
                        border: 1px solid $neutral;
                        padding-right: 60px;
                        height: 56px;
                        
                        @media (max-width: 991px) {
                            &:not(.search-icon) {
                                width: 0;
                                visibility: hidden;
                            }
                        }
                    }
                    .form-label { 
                        color: $neutral; 
                        left: -1px;
                        bottom: -8px;

                        @media (max-width: 991px) {
                            display: none;
                        }
                    }
                    .search-icon {
                        height: fit-content;
                    }
                }
            }
            &-newsletter {
                background: #FBF6FB;
                border-radius: 20px 0 20px 0;
                padding: 60px 40px;
                margin-top: 32px;

                @media (max-width: 991px) {
                    display: none;
                }
                &-title {
                    color: $black;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                }                
                .text { 
                    color: $neutral;
                    max-width: 228px; 
                    margin-block: 13px 22px;
                }
                .form-control {
                    height: 62px;
                }
                .form-label {
                    font-size: 12px;
                    line-height: 40px;
                    background-color: #FBF6FB;
                }
                button {
                    width: 100%;

                    .button {
                        margin-top: 8px;
                        width: 100%;
                        padding-inline: 8px;
                    }
                }
            }
            &-tags {
                margin-top: 32px;

                @media (max-width: 991px) {
                    display: none;
                }
                &-title {
                    color: $black;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 27px;
                    padding: 12px 20px;
                    margin-bottom: 32px;
                }
            }
            // mobile only
            &-categories {
                display: none;

                @media (max-width: 991px) {
                    flex-grow: 1;
                    padding: 0;
                    margin-top: 0;
                    background: transparent;
                    display: flex;

                    &-title {
                        color: $black;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;

                        background: #FBF6FB;
                        border-radius: 20px 0 20px 0;
                        padding: 13px 20px;
                        position: relative;
                        max-width: 171px;
                        
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        strong { display: none; }
                        ion-icon { 
                            display: flex !important; 
                            color: $accentColor;
                            font-size: 15px;
                        }
                    }
                    .cat {
                        position: absolute;
                        top: 111%;
                        max-width: 251px;
                        padding: 20px;
                        background: #FBF6FB;
                        border-radius: 0 0 20px 0;

                        display: none;
                        opacity: 0;
                        transition: opacity .5s ease-in-out;

                        &.show {
                            display: block;
                            opacity: 1;

                            & ~ .aside-categories { background: #FBF6FB !important; }
                        }
                        &-item {
                            display: block;
                            padding-block: 12px;
                            transition: all .3s ease-in-out;
    
                            &.active,
                            &:hover {
                                h6 { color: $accentColor; }
                            }
                            h6 {
                                color: $neutral;
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 27px;                            
                            }
                        }
                    }
                }
            }
        }
    }
    .blog {
        .col-lg-5 { display: none; }
        .col-lg-7 {
            width: 100%;
            text-align: center;
        }
    }
}