.button {
    background-color: #891441;
    border-radius: 20px 0 20px 0;
    padding-inline: 24px;
    width: 227px;
    height: 60px;
    text-decoration: none;
    transition: all .3s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &, & > h6 {
        font-family: "Poppins", sans-serif;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        line-height: 60px;
    }
    &:hover {
        translate: 0 -3px;
        color: #fff;
    }
}
.btn-light {
    background-color: #fff !important;
    color: #891441 !important;
}