#success {
    min-height: 100vh;
    padding: 118px 25px 97px;
    background-color: $accentColor;

    display: grid;
    place-items: center;

    @media (max-width: 1100px) {
        padding: 58px 0 0;
    }
    & > .container {
        padding-inline: 0;

        @media (min-width: 1400px) {
            max-width: 1406px !important;
        }
    }
    .success {
        background-color: #fff;
        position: relative;
        max-width: 100%;
        border-radius: 20px 0 20px 0;
        padding: 152px 126px 172px 161px;

        @media (max-width: 1500px) {
            padding: 152px 80px 172px;
        }
        @media (max-width: 1100px) {
            border-radius: 20px 0 0 0;
            width: 100%;
            padding: 69px 25px 70px;
            height: 100%;

            & > .row {
                row-gap: 63px;
            }
        }
        &--text {
            max-width: 475px;
            padding-top: 55px;

            @media (max-width: 1100px) {
                max-width: 100%;
                text-align: center;
                padding-top: 0;
            }
            .main-title {
                color: $black;
                font-size: 60px;
                font-weight: 300;
                line-height: 70px;
                margin-block-end: 32px;

                @media (max-width: 1100px) {
                    font-size: 30px;
                    line-height: 40px;
                    margin-block-end: 20px;
                }
                strong {
                    color: $accentColor;
                    font-family: inherit;
                    font-weight: 700;
                }
            }
            .sub-title {
                color: $neutral;

                @media (max-width: 1100px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .button { 
                margin-block-start: 60px; 
                width: 240px;

                @media (max-width: 1100px) {
                    margin-block-start: 47px;
                    width: Min(500px, 100%);
                    margin-inline: auto;
                }
            }
        }
        &--img {
            display: flex;
            justify-content: end;

            @media (max-width: 1100px) {
                justify-content: center;
            }
            .img-wrapper {
                width: 494.1px;
                height: 403px;
                border-radius: 20px 0 20px 0;
                overflow: hidden;

                @media (max-width: 1100px) {
                    width: 100%;
                    height: 319px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .close-btn {
            position: absolute;
            top: 32px;
            right: 32px;

            @media (max-width: 1100px) {
                top: 25px;
                right: 25px;
            }
            ion-icon {
                font-size: 39px;
                color: $accentColor;

                @media (max-width: 1100px) {
                    font-size: 24px;
                }
            }
        }
    }
}