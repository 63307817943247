#policy {
    .policy {
        padding: 120px 80px 152px;

        @media (max-width: 1100px) {
            padding: 60px 25px;
        }
        h2 {
            color: $accentColor;
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
            
            margin-bottom: 32px;
        }
        p {
            color: $neutral;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
        }
        p + p { margin-top: 32px; }
        p + h2 { 
            margin-top: 60px;

            @media (max-width: 1100px) {
                margin-top: 40px;
            }
        }
    }
}

#innerBanner {
    $navbarHeight: 98.3px;

    height: Min(528px, 100vh);
    margin-top: $navbarHeight;
    padding-top: 10.5vh;
    background: #FBF6FB;
    position: relative;
    isolation: isolate;

    @media (max-width: 992px) {
        $navbarHeight: 89px;

        height: Min(713px, 100vh);
        margin-top: $navbarHeight;
        padding-top: 60px;

        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
    }
    &::after {
        content: '';
        position: absolute;
        inset: 0 0 0 85%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) -16.72%, rgba(0, 0, 0, 0) 97.69%);
        mix-blend-mode: multiply;
        z-index: -1;

        @media (max-width: 992px) {
            display: none;
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-height: 445px;

        @media (max-width: 992px) {
            padding-inline: 25px;
            max-height: 303px;
        }
    }
    .hero-img {
        position: absolute;
        width: 842px;
        inset: 0 0 0 auto;
        z-index: -2;

        @media (max-width: 1400px) {
            width: Min(842px, 45vw);
        }
        @media (max-width: 992px) {
            width: 100% !important;
            position: relative;
            height: Min(390px, 100%);
            isolation: isolate;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) -16.72%, rgba(0, 0, 0, 0) 97.69%);
                mix-blend-mode: multiply;
                z-index: 0;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (max-width: 1100px) {
                max-height: 100%;
            }
        }
    }
    .content-wrapper {
        max-width: 630px;

        @media (max-width: 1100px) {
            max-width: 100%;
        }
        .title {
            font-size: 60px;
            line-height: 70px;
            margin-top: 0;
            margin-block-end: 40px;

            @media (max-width: 1100px) {
                font-size: 30px;
                line-height: 40px;
                max-width: 325px;
            }
        }
        .text {
            color: $neutral;
            max-width: 458px;
        }
        p + p { margin-top: 8px; }
    }
    .breadcumbs {
        padding-bottom: 32px;
        
        @media (max-width: 1100px) {
            padding-bottom: 20px;
        }
        ul {
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 0;

            li {
                color: $neutral;
                font-family: inherit;
                font-size: 16px;
                font-weight: 600;
                line-height: 33px;
                
                @media (max-width: 1100px) {
                    font-size: 12px;
                    line-height: 18px;
                }
                &:last-child {
                    color: $accentColor;
                }
                a {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
                ion-icon {
                    font-size: 16px;
                    color: $neutral;
                }
            }
        }
    }
}