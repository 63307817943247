//LIBS
@import 'libs/animations.scss';

// DEFAULT
@import 'general/reset';
@import 'general/media-screen';
@import 'general/variable';
@import 'general/color';
@import 'general/fonts';
@import 'general/mixins';
@import 'general/common';

// LAYOUTS
@import 'layout/header';
@import 'layout/footer';

// COMPONENTS
@import 'components/Modal/zoom_modal.scss';
@import 'components/Modal/forms/error_message.scss';
@import 'components/Modal/forms/sucess_message.scss';
@import 'components/Modal/forms/loading.scss';
@import 'components/buttons.scss';
@import 'components/inputs.scss';
@import 'components/cookies.scss';


// PAGES
@import 'pages/page404';
@import 'pages/home';
@import 'pages/policy';
@import 'pages/blog';
@import 'pages/success';
@import 'pages/blog-detail';
@import 'pages/portfolio';
@import 'pages/portfolio-detail';

//sass --watch resources/site/assets/sass/main.scss:public/site/css/main.css

// general styling & utility classes
* {
    font-family: "Open Sans", sans-serif;
}
body { scroll-behavior: auto; }
h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    margin: 0;
}
.sub-title {
    color: $accentColor;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;

    @media (max-width: 1100px) {
        font-size: 20px;
        line-height: 30px;
    }
}
.title {
    color: $black;
    font-size: 45px;
    font-weight: 350;
    line-height: 60px;
    margin-top: 12px;

    @media (max-width: 1100px) {
        font-size: 30px;
        line-height: 40px;
    }
    strong {
        color: $accentColor;
        font-family: inherit;
        font-weight: 700;
    }
}
.text {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}
.bg-img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        inset: 0;
    }
}
.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .tag {
        padding: 12px 20px;
        height: 51px;
        border: 1px solid #5B5B5B33;
        border-radius: 20px 0 20px 0;

        display: grid;
        place-items: center;
        text-align: center;
        transition: all .3s ease-in-out;

        h6 {
            color: $neutral;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
        }
        &.active,
        &:hover {
            border: 1px solid #89144133;

            h6 { color: $accentColor; }
        }
        &:hover { translate: 0 -3px; }
    }
}
.card-item {
    position: relative;
    overflow: hidden;

    & > a:hover {
        & > img {
            transform: scale(1.1);
            filter: brightness(30%);
        }
        .plus {
            img { rotate: -180deg; }
        }
        .hover-title {
            opacity: 1;
        }
    }
    & > a > img {
        height: 100%;
        transition: all .5s ease;
    }
    .hover-title {
        position: absolute;
        inset: 50% auto auto 50%;
        translate: -50% -50%;
        width: 90%;
        text-align: center;
        opacity: 0;
        transition: all .5s ease-in-out;

        h3 {
            color: #fff;
            font-size: Min(38px, 2vw);
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;

            @media (max-width: 992px) {
                font-size: 16px;
            }
        }
    }
}
.plus {
    position: absolute;
    inset: 0 auto auto 0;
    background-color: $accentColor;
    width: 77px;
    aspect-ratio: 1;
    border-radius: 0 0 20px 0;
    isolation: isolate;

    display: grid;
    place-items: center;

    @media (max-width: 1100px) {
        width: 47px !important;
    }
    & > img {
        position: unset !important;
        transition: all .3s ease-in-out;

        @media (max-width: 1100px) {
            width: 9.5px;
            height: 9.5px;
        }
    }
}
a { text-decoration: none; }
.hidden {
    opacity: 0;
}