$accentColor: #891441;
$black: #222222;
$neutral: #5B5B5B;

/*------------------------- Containers -----------------------*/

.termsfeed-com---nb .cc-nb-main-container{

}

.termsfeed-com---palette-dark.termsfeed-com---nb{
    background: #891441;
    background-color: #891441;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    box-shadow: 0px 3px 10px #222222;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    background-color: white;
}
/*-------------------------TEXTS -----------------------*/


/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree{
    background: #222222;
    color: #fff;
    margin-right: 1rem !important;
    transition: background-color 1s;
    &:hover{
        background-color: #891441;
    }
}
.termsfeed-com---palette-dark .cc-nb-reject{
    color: #fff;
    background: linear-gradient(141deg, #891441 31.89%, transparent) #891441 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    &:hover{
        background-color: #891441;
    }
}
.termsfeed-com---palette-dark .cc-nb-okagree{
    background-color: #891441;
}
.termsfeed-com---nb-simple{
    left: inherit;
    right: 20vw;
    bottom: 2vh;
    border: 0.2px solid #ffffff;
    transition: 0.4s;
    &::after{
    content: "";
    width: 100%;
    height: 3px;
    background-color: #891441;
    position: absolute;
    left: 0;
    bottom: 0;
    }
}
// Accept
.termsfeed-com---palette-light .cc-nb-okagree{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
// Decline
.termsfeed-com---palette-light .cc-nb-reject{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
// Config
.termsfeed-com---palette-light .cc-nb-changep{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
