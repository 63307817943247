.floating-label {
    position: relative;

    .form-control {
        width: 100%;
        height: 77px;
        padding: 13px 32px;
        border: 1px solid #4B4B4B;
        border-radius: 20px 0 20px 0;
        background-color: transparent;

        color: #5B5B5B;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 60px;

        &:focus {
            color: inherit;
            box-shadow: none;
        }
    }
    textarea.form-control {
        min-height: 181px;
        resize: none;

        @media (max-width: 1100px) {
            min-height: 98px;
        }
    }
    .form-label {
        background-color: #fff;
        color: #891441;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        margin: 0;
        padding-right: 10px;

        position: absolute; 
        bottom: -17px;
        left: 0;

        @media (max-width: 1100px) {
            font-size: 16px;
        }
    }
    // for search inputs
    .search-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 56px;
        aspect-ratio: 1;
        border-radius: 20px 0 20px 0;

        display: grid;
        place-items: center;

        background-color: #891441;
    }
}
.form-check {
    margin-top: 19.5px;
    text-align: start;

    &-input {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        translate: 0 -3px;
        border-radius: 35% 0 35% 0 !important;

        &:checked {
            background-color: #891441;
            border-color: #891441;
        }
        &:focus {
            box-shadow: 0 0 0 0.25rem #89144142;
            border-color: #891441;
        }
    }
    &-label {
        color: #5B5B5B;
        font-family: inherit;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;

        a { 
            color: #891441;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}