#page404{
	.home-banner {
		height: 100vh;

		@media (max-width: 1100px) {
			height: auto;
			max-height: 100%;
		}
		&--content {
			@media (max-width: 1100px) {
				padding-top: 81px;

				.navbar { 
					background: #fff !important;
					padding: 12px 20px !important;

					position: absolute;
					inset: 0 0 auto 0;
					width: 100%;
				}
			}
			&, .navbar { background: #FBF6FB; }
			&-text {
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: center;
				gap: 40px;
				max-width: 458px;
				translate: 0 -80px;

				@media (max-height: 640px) {
					gap: 20px;
				}
				@media (max-width: 1100px) {
					text-align: center;
					align-items: center;
					max-width: 100%;
					translate: 0 0;
				}
				.main-title {
					min-height: auto;
					translate: 0 0;
				}
				.text {
					color: #5B5B5B;
					font-size: 16px;
					font-weight: 400;
					line-height: 25px;
				}
				.button { 
					width: 241px;

					@media (max-width: 1100px) {
						width: Min(100%, 500px);
						margin-inline: auto;
					}
				}
			}
			.vertical-text { display: none; }
		}
		&--img {
			display: flex;
			
			@media (max-width: 1100px) {
				height: 554px;
				max-height: 100% !important;
			}
			img {
				min-width: 100%;
				min-height: 100%;
				object-fit: cover;

			}
		}
	}
}