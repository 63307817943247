$accentColor: #891441;
$black: #222222;
$neutral: #5B5B5B;

.line {
    width: 100%;
    height: fit-content;
    position: relative;
    overflow: hidden;
}

#home {
    .about {
        padding-block: 132.9px 183px;
        background: #FBF6FB;

        @media (max-width: 1100px) {
            padding-block: 70px 49px;
        }

        &--title {
            padding-left: Min(320px, 31%);
            margin-bottom: 111px;

            @media (max-width: 1500px) {
                padding-left: 119px;
            }

            @media (max-width: 1100px) {
                padding-left: 25px;
                margin-bottom: 40px;
            }

            .title {
                max-width: 675px;

                @media (max-width: 1100px) {
                    max-width: 343px;
                }
            }
        }

        &--content {
            padding-inline: 125px;

            @media (max-width: 1100px) {
                padding-inline: 25px;
            }

            .wrapper {
                max-width: 1337px;
                display: flex;
                gap: 142px;

                @media (max-width: 1500px) {
                    gap: 90px;
                }

                @media (max-width: 1100px) {
                    flex-direction: column;
                    gap: 49px;
                }

                &--img {
                    border-radius: 0 0 20px 0;
                    overflow: hidden;

                    @media (max-width: 1100px) {
                        height: 276px;
                        width: Min(500px, 100%);
                        margin-inline: auto;
                    }

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: inherit;
                    }
                }

                &--text {
                    max-width: 420px;

                    @media (max-width: 1100px) {
                        max-width: 100%;
                    }

                    .text {
                        color: $neutral;
                    }

                    .text+.text {
                        margin-top: 25px;
                    }

                    h6 {
                        color: $accentColor;
                        font-size: 30px;
                        font-weight: 400;
                        line-height: 40px;
                        max-width: 367px;
                        margin-top: 89px;

                        @media (max-width: 1100px) {
                            font-size: 30px;
                            line-height: 40px;
                            max-width: 100%;
                            margin-top: 63px;
                        }
                    }
                }
            }
        }
    }

    .testimonials {
        background: $accentColor;
        padding: 120px 25px;
        min-height: 896px;
        isolation: isolate;
        position: relative;

        @media (max-width: 992px) {
            min-height: auto;
            padding: 60px 0;

            &>.container {
                padding-inline: 25px;
            }
        }

        &--title {

            .sub-title,
            .title {
                color: #fff;
            }

            .title {
                margin-block: 14px 105px;
                max-width: 675px;

                @media (max-width: 992px) {
                    margin-block-end: 44px;
                }

                strong {
                    color: inherit;
                }
            }
        }

        &--slider {
            .card-item {
                background: #FBF6FB;
                padding: 53px 38px;
                border-radius: 20px 0 20px 0;
                max-width: 414px;
                min-height: 482px;
                margin-right: 20px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                isolation: isolate;

                @media (max-width: 992px) {
                    max-width: 340px;
                    height: auto;
                    min-height: 374px;
                    padding: 38px 21px 41px 23px;
                }

                .text:not(h6) {
                    color: $black;
                    margin-bottom: 44px;

                    @media (max-width: 992px) {
                        margin-bottom: 32px;
                    }
                }

                h5 {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 28px;
                }

                h6 {
                    color: $accentColor !important;
                    font-weight: 500 !important;
                    margin-top: 4px;
                    max-width: 70%;
                }

                h5,
                h6 {
                    font-family: "Open Sans", sans-serif;
                }

                .icon {
                    position: absolute;
                    right: 38px;
                    bottom: 43px;
                    z-index: 0;

                    @media (max-width: 992px) {
                        bottom: 31px;
                        right: 21px;
                        width: 78px;
                        aspect-ratio: 1;
                    }

                    @media (max-width: 450px) {
                        width: 42px;
                    }
                }
            }

            .autoplay {
                max-width: 1310px;
                margin-inline: auto;
                position: relative;

                @media (max-width: 1500px) {
                    display: flex;
                    flex-direction: column;
                }

                .slick-slide {
                    height: fit-content;
                }
            }

            .prev,
            .next {
                width: 56px;
                aspect-ratio: 1;
                background-color: #FBF6FB;

                display: grid;
                place-items: center;

                position: absolute;
                top: 169px;

                @media (max-width: 1500px) {
                    position: relative;
                    top: auto;
                    bottom: 0;
                }
            }

            .prev {
                left: 82px;
                border-radius: 20px 0 0 0;

                @media (max-width: 1700px) {
                    left: 0;
                }

                @media (max-width: 1500px) {
                    position: unset;
                }

                img {
                    rotate: -180deg;
                }
            }

            .next {
                right: 82px;
                border-radius: 0 0 20px 0;

                @media (max-width: 1700px) {
                    right: 0;
                }

                @media (max-width: 1500px) {
                    position: unset;
                }
            }

            .dots-container {
                @media (max-width: 1500px) {
                    margin-top: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 32px;
                }
            }
        }
    }

    .services {
        padding: 103.6px 96.3px 90px;
        overflow: hidden;

        @media (max-width: 1550px) {
            padding-inline: 70px;
        }

        @media (max-width: 1100px) {
            padding: 48px 25px 96px;
        }

        &--title {
            text-align: center;

            @media (max-width: 1100px) {
                .line {
                    overflow: visible;
                }
            }
        }

        &--content {
            display: flex;
            justify-content: flex-end;
            margin-top: 67.6px;

            @media (max-width: 1100px) {
                justify-content: flex-start;
                margin-top: 49px;
            }

            .wrapper {
                display: flex;
                gap: 80px;
                max-width: calc(908px + 80px + 439px);

                @media (max-width: 1500px) {
                    gap: 40px;
                }

                @media (max-width: 1100px) {
                    flex-direction: column;
                    gap: 47px;
                }

                &--text {
                    padding-top: 75.9px;
                    max-width: 439px;

                    @media (max-width: 1100px) {
                        max-width: 100%;
                        padding-top: 0;
                    }

                    .info {
                        @media (max-width: 1100px) {
                            text-align: center;
                        }

                        .text {
                            color: $neutral;
                        }
                    }

                    .accordion {
                        &-button {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            padding: 0;

                            &::after {
                                display: none;
                            }

                            &[aria-expanded="false"] {
                                min-width: 100%;
                                color: inherit;

                                h6 {
                                    color: inherit;
                                }
                            }

                            &[aria-expanded="true"] {
                                background-color: inherit;
                                color: inherit;
                                box-shadow: none;

                                h6 {
                                    color: $accentColor;
                                }
                            }

                            &:not(.collapsed) {
                                background-color: transparent;
                                box-shadow: none;
                            }

                            &:focus {
                                box-shadow: none;
                            }

                            h6 {
                                color: $black;
                                font-size: 28px;
                                font-weight: 500;
                                line-height: 40px;

                                @media (max-width: 1100px) {
                                    font-size: 20px;
                                }
                            }

                            ion-icon {
                                font-size: 28px;
                                color: $accentColor;

                                @media (max-width: 1100px) {
                                    font-size: 20px;
                                }
                            }
                        }

                        &-body {
                            padding: 17px 0 53px;

                            @media (max-width: 1100px) {
                                padding: 16px 0 47px;
                            }

                            .text {
                                color: $neutral;

                                @media (max-width: 1100px) {
                                    font-size: 14px;
                                }
                            }

                            ul {
                                color: $neutral;
                                margin-block-start: 20px;
                                padding-inline-start: 20px;

                                li {
                                    list-style-type: disc !important;
                                    font-size: 16px;
                                    font-weight: 400;

                                    &::marker {
                                        color: $accentColor;
                                    }
                                }
                            }

                            .img-wrapper {
                                display: none;

                                @media (max-width: 1100px) {
                                    display: block;
                                    width: fit-content;
                                    margin-inline: auto;
                                    margin-block-start: 47px;

                                    img {
                                        max-width: 100%;
                                    }
                                }
                            }
                        }

                        &-item {
                            border: 0 !important;
                            min-width: 439px;

                            @media (max-width: 1500px) {
                                min-width: 100%;
                                max-width: 100%;
                            }
                        }

                        .accordion-item+.accordion-item {
                            margin-top: 12px;
                        }
                    }
                }

                &--img {
                    width: min(907px, 48vw);
                    height: 723px;
                    position: relative;

                    @media (max-width: 1500px) {
                        img {
                            max-width: 100%;
                        }
                    }

                    @media (max-width: 1100px) {
                        display: none;
                    }

                    img {
                        max-width: 100%;
                        object-fit: contain;
                        border-radius: 20px 0 20px 0;

                        position: absolute;
                        inset: 0;
                    }
                }
            }
        }
    }

    .portfolio {
        position: relative;
        isolation: isolate;

        &--grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            @media (max-width: 1100px) {
                grid-template-columns: repeat(2, 1fr);
            }

            .card-item {
                &:nth-child(1) {
                    height: 1031px;

                    @media (max-width: 992px) {
                        height: 353px;
                    }
                }

                &:nth-child(3),
                &:nth-child(4) {
                    max-height: 643px;
                }
            }
        }

        &--info {
            background-color: #fff;
            padding: 0 115px 80px;
            width: Min(62vw, 1112px);
            grid-column: span 2;

            @media (max-width: 1350px) {
                padding-right: 80px;
            }

            @media (max-width: 1100px) {
                order: 0;
                width: 100%;
                padding: 0 25px 80px;
            }

            .button {
                margin-top: 84px;
                width: fit-content;

                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }

        .card-item {
            &>a>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 1100px) {
                &:nth-child(3) {
                    order: 2;
                }

                &:nth-child(4) {
                    order: 3;
                }
            }

            &:nth-child(1) {
                grid-column: span 2;
                grid-row: span 2;

                @media (max-width: 1100px) {
                    order: 1;
                }

                &>a>img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .row-2 {
            .card-item {

                &:nth-child(1),
                &:nth-child(2) {
                    height: 707px;
                    max-width: 561px;

                    @media (max-width: 1100px) {
                        display: none;
                    }
                }

                &:nth-child(3) {
                    position: relative;
                    z-index: 1;
                    height: 876px;
                    width: 41.55vw;
                    flex-grow: 1;

                    &>a>img {
                        width: 100%;
                        object-fit: cover;
                    }

                    @media (max-width: 1100px) {
                        order: 1;
                        width: 100%;
                        height: 493px;
                    }
                }
            }
        }

        .btn-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 80px;
            padding-inline: 25px;


            @media (max-width: 1100px) {
                margin-top: 50px;

                .button {
                    width: Min(100%, 500px);
                }
            }
        }
    }

    .clients {
        padding: 111px 25px 180px;

        @media (max-width: 1100px) {
            padding: 77px 25px;
        }

        &--title {
            text-align: center;

            @media (max-width: 1100px) {
                .line {
                    overflow: visible;
                }
            }
        }

        &--img {
            margin-top: 132px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 98px 150px;
            max-width: 1196px;
            margin-inline: auto;

            @media (max-width: 1100px) {
                margin-top: 93px;
                gap: 80px 60px;
            }

            & > div {
                width: 119px;
                height: 75px;

                &:hover {
                    img {
                        scale: 1.1;
                        opacity: 1;
                        filter: none;
                    }
                }
            }
            
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: all .3s ease-in-out;

                filter: grayscale(100%);
                opacity: .5;

                @media (max-width: 1100px) {
                    max-width: 89px;
                    max-height: 32px;
                    filter: none;
                }
            }
        }
    }

    .blog {
        .col-lg-8 {
            max-width: 856px;
        }

        .post {
            max-width: Min(100%, 414px);
        }
    }
}

// reusable sections
.home-banner {
    height: Min(960px, 100vh);
    max-height: 100vh;
    display: flex;
    overflow: hidden;

    @media (max-width: 1100px) {
        height: Min(799px, 100vh);
        max-height: auto;
        flex-direction: column;
    }

    &--content {
        position: relative;
        height: 100%;
        width: Min(1077.5px, 57vw);
        z-index: 0;
        transition: all .9s ease-in-out;

        @media (max-width: 1100px) {
            width: 100%;
            padding-bottom: 32px;
        }

        .container {
            margin: 0;
            padding-left: Min(320px, 31%);
            padding-right: 0;
            height: 100%;

            @media (max-width: 1630px) {
                padding-left: 80px;
            }

            @media (max-width: 1100px) {
                padding-inline: 25px;
                max-width: 100%;
            }
        }

        .navbar {
            padding: 39px 53.5px 0 0 !important;

            position: relative;
            z-index: 3;

            @media (max-width: 1100px) {
                padding: 25px 20px !important;

                &-logo img {
                    width: 71px;
                    height: 59px;
                }
            }
        }

        &-text {
            display: grid;
            height: 100%;
            align-items: center;

            @media (max-width: 1100px) {
                height: auto;
                padding-top: 27px;
            }

            div {
                max-width: 630px;
                translate: 0 -111px;

                @media (max-height: 530px) {
                    translate: 0 -61px;
                }

                @media (max-width: 1100px) {
                    max-width: 400px;
                    translate: 0 0;
                }
            }

            .main-title {
                font-size: 60px;
                font-weight: 300;
                line-height: 70px;

                @media (max-width: 1100px) {
                    font-size: 30px;
                    line-height: 40px;
                }

                strong {
                    color: $accentColor;
                    font-family: inherit;
                    font-weight: 700;
                }
            }

            .text {
                font-size: 30px;
                line-height: 40px;
                margin-top: 20px;
                max-width: 581px;

                @media (max-width: 1100px) {
                    font-size: 18px;
                    line-height: 24px;
                    margin-top: 16px;
                    max-width: 350px;
                }

                strong {
                    color: $accentColor;
                    font-weight: 700;
                }
            }
        }

        .vertical-text {
            position: absolute;
            bottom: 38.6px;
            right: 38.6px;
            writing-mode: vertical-rl;
            cursor: pointer;

            display: flex;
            align-items: center;
            gap: 12px;

            @media (max-width: 1100px) {
                display: none;
            }

            h6 {
                color: $black;
                font-size: 18px;
                font-weight: 400;
                line-height: 50px;

                @media (max-height: 740px) {
                    opacity: 0;
                }
            }

            svg {
                width: 33px;
                height: 53px;
                cursor: pointer;

                rect {
                    stroke: $accentColor;
                }

                path {
                    width: 3px;
                    height: 10px;
                    border-radius: 25%;
                    stroke: $accentColor;
                    animation-name: scroll;
                    animation-duration: 2.2s;
                    animation-timing-function: cubic-bezier(.15, .41, .69, .94);
                    animation-iteration-count: infinite;

                    @keyframes scroll {
                        0% {
                            opacity: 0;
                        }

                        10% {
                            transform: translateY(0);
                            opacity: 1;
                        }

                        100% {
                            transform: translateY(15px);
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &--img {
        width: Min(842.5px, 45vw);
        position: relative;
        z-index: 2;
        overflow: hidden;
        background-color: #fff;

        @media (max-width: 1100px) {
            width: 100%;
            max-height: 445px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (max-width: 1100px) {
                max-height: 249px !important;
                width: 100%;
                object-fit: cover;
            }

            &:nth-child(1) {
                max-width: 427px;
                max-height: 538px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                }
            }

            &:nth-child(2) {
                max-width: 416px;
                max-height: 532px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                }
            }
        }
    }

    .menu {
        width: Min(842.5px, 43.5vw);
        background-color: #FBF6FB;
        transition: all .9s ease-in-out;

        position: absolute;
        inset: 0 0 0 auto;
        z-index: 1;

        @media (max-height: 770px) {
            &-list {
                li {
                    padding-block: 0 !important;
                }
            }
        }

        @media (max-width: 1100px) {
            inset: 0 auto 0 0;
            transform: translateX(-100%);
            z-index: 1000;

            &,
            &.opened {
                width: Min(500px, 100%) !important;
            }

            &.opened {
                transform: translateX(0) !important;
            }
        }

        &.opened {
            width: Min(1077.5px, 57vw);
            transform: translateX(-43.5vw);
        }

        &-nav {
            padding: 39px 53.5px 0 0;
            background-color: inherit;

            @media (max-width: 1100px) {
                padding: 25px 20px;

                &--logo img {
                    width: 71px;
                    height: 59px;
                }
            }

            &--hamburger ion-icon {
                color: $accentColor;
                font-size: 39px;
            }
        }

        &-list {
            margin: auto 0;
            padding: 0;

            li {
                padding-block: 12px;

                a {
                    color: $black;
                    font-family: "Poppins", sans-serif;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 40px;
                    transition: background-position .3s ease;

                    position: relative;
                    overflow: hidden;
                    display: inline-block;

                    &:not(.active) {
                        background: linear-gradient(to right, $accentColor, $accentColor 50%, $black 50%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-size: 200% 100%;
                        background-position: 100%;
                    }

                    &.active {
                        color: $accentColor;
                        font-weight: 700;
                    }

                    &:hover {
                        background-position: 0 100%;
                    }
                }
            }

            li+li {
                margin-top: 12px;
            }
        }

        .container {
            margin: 0;
            padding-left: Min(320px, 31%);
            padding-right: 0;
            height: 100%;

            display: flex;
            flex-direction: column;

            @media (max-width: 1630px) {
                padding-left: 80px;
            }

            @media (max-width: 1100px) {
                padding-inline: 25px;
                max-width: 100%;
            }
        }

        .socials {
            position: absolute;
            right: 54px;
            bottom: 160px;
            width: fit-content;

            img {
                width: 24px;
                aspect-ratio: 1;
            }
        }
    }
}

.blog {
    background: #FBF6FB;
    padding: 109px 25px 218px;

    @media (max-width: 1100px) {
        padding: 40px 25px 51px;
    }

    &--title {
        margin-bottom: 66px;

        @media (max-width: 1100px) {
            margin-bottom: 53px;
        }

        .col-lg-4 {
            display: flex;
            justify-content: end;
            align-items: end;
            padding-bottom: 32.5px;

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .col-lg-8 {
            @media (max-width: 1100px) {
                width: 100%;
            }
        }
    }

    &--posts {
        @media (max-width: 1100px) {
            .row {
                row-gap: 36px;
            }

            .posts {
                margin-inline: auto;
            }
        }
    }

    .container>.btn-wrapper {
        display: none;

        @media (max-width: 1100px) {
            display: flex;
            justify-content: center;
            margin-top: 39px;

            .button {
                width: Min(500px, 100%);
            }
        }
    }
}

.post {
    // card blog
    position: relative;
    border-radius: 20px 0 20px 0;
    min-height: 575px;
    height: 100%;
    overflow: hidden;
    isolation: isolate;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    // align-items: end;
    box-shadow: 9px 10px 12px -7px rgba(184, 184, 184, 0.82);
    -webkit-box-shadow: 9px 10px 12px -7px rgba(184, 184, 184, 0.82);
    -moz-box-shadow: 9px 10px 12px -7px rgba(184, 184, 184, 0.82);

    @media (max-width: 1100px) {
        min-height: 471px;
    }
    &:hover {
        .bg-img {
            height: 100%;
            flex-grow: 1;

            .hover-img {
                width: 100%;
                height: 100%;
                aspect-ratio: 85/53;
                object-fit: cover;
            }
            &::after {
                opacity: .75;
            }
        }

        .bg-color {
            height: 0;
        }

        .text-wrapper {
            width: 100%;
            z-index: 3;
        }

        .post-title,
        .text,
        .date {
            color: #fff;
        }

        .plus {
            background-color: #fff;

            &>img {
                rotate: -180deg;
                filter: invert(81%) sepia(25%) saturate(6759%) hue-rotate(322deg) brightness(92%) contrast(94%);
            }
        }

        &::before {
            opacity: 1;
        }
    }
    &::before {
        content: '';
        position: absolute;
        inset: 0 auto auto 0;
        width: 38px;
        height: 35px;
        background-color: #fff;
        z-index: 0;
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }
    .text-wrapper {
        flex-grow: 1;
        padding: 40px 19px 40px 27px;
        position: unset;
        transition: position .3s ease-in-out;

        @media (max-width: 1100px) {
            padding: 46px 21px 12px 14px;
        }
    }
    &-title {
        color: $black;
        font-size: 30px;
        font-weight: 400;
        line-height: 35px;
        transition: all .3s ease-in-out;
    }
    .date {
        color: $accentColor;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin-block: 10px 18px;
        transition: all .3s ease-in-out;

        @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 21px;
            margin-block: 7px 14px;
        }
    }
    .text {
        color: $neutral;

        @media (max-width: 1100px) {
            font-size: 14px;
        }
    }
    .plus {
        width: 77px;
        isolation: isolate;
        transition: all .3s ease-in-out;
    }
    .bg-img {
        position: relative;
        // inset: 0 0 auto 0;
        height: 264px;
        transition: all .4s ease-in-out;
        background-color: #fff;

        img {
            border-radius: 0 0 20px 0;
            background-color: #fff;
        }
        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: $accentColor;
            opacity: 0;
            z-index: 0;
            transition: all .3s ease-in-out;
        }
    }
    .bg-color {
        position: absolute;
        inset: auto 0 0 0;
        height: 54%;
        background-color: #fff;
        z-index: -1;
        transition: all .4s ease-in-out;
    }
}

.contact {
    padding: 117px 80px 97px;
    background: $accentColor;
    text-align: center;

    @media (max-width: 1100px) {
        padding: 49px 25px 47px;
    }

    &--title {
        margin-bottom: 70px;

        @media (max-width: 1100px) {
            margin-bottom: 53px;
        }

        .title {
            margin-block: 0 31px;
            color: #fff;

            @media (max-width: 1100px) {
                margin-block: 0 53px;
            }
        }

        .sub-title {
            color: #fff;
        }
    }

    &--forms {
        form {
            .row {
                display: flex;
                gap: 12px;

                @media (max-width: 1100px) {
                    gap: 24px;
                }

                &>* {
                    width: auto;
                    padding: 0;
                }
            }

            .row+.row {
                margin-top: 21px;

                @media (max-width: 1100px) {
                    margin-top: 24px;
                }
            }

            .floating-label {
                max-width: 100%;
                flex-grow: 1;

                .form-control {
                    background-color: transparent;
                    color: #fff;
                    border-color: #fff;
                }

                .form-label {
                    background-color: #891441;
                    color: #fff;
                }
            }

            .form-check {
                margin-top: 16px;

                &-input {
                    color: #891441;

                    &:checked {
                        $bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                        background-image: $bs-form-check-bg-image;

                        background-color: #fff;
                        color: #891441;
                    }
                }

                &-label {
                    color: #fff;

                    a {
                        color: #fff;
                    }
                }

                .error--message {
                    text-align: start !important;
                    margin-top: 8px;
                    padding-left: 10px;
                    translate: 0 !important;
                }
            }

            .btn-wrapper {
                margin-top: 20px;

                @media (max-width: 1100px) {
                    margin-top: 50px;
                }

                .button {
                    width: 325px;
                    height: 77px;

                    @media (max-width: 1100px) {
                        width: Min(325px, 100%);
                    }
                }
            }

            // validation
            .show--error {
                input {
                    color: #ffb72f !important;
                    border: 1px solid #ffb72f !important;
                }

                label {
                    color: #ffb72f !important;
                }

                .error--message {
                    display: block;
                    font-size: 12px;
                    color: #ffb72f !important;
                    text-align: end;
                    translate: -8px 8px;
                }
            }
        }
    }
}

#floating {
    position: fixed;
    right: 40px;
    bottom: 40px;
    transition: all .3s ease-in-out;
    z-index: 1000;
    opacity: 0;

    @media (max-width: 992px) {
        right: 20px;
    }

    &:hover {
        img {
            filter: drop-shadow(0 0 0.25rem #8914416d);
            translate: 0 -5px;
        }
    }

    img {
        width: 60px;
        aspect-ratio: 1;
        transition: all .3s ease-in-out;

        @media (max-width: 992px) {
            width: 40px;
        }
    }
}

// header css
#header {
    opacity: 1;
    translate: none;
    transition: all .5s ease-in-out;

    &.hidden {
        opacity: 0;
        pointer-events: none;
        translate: 0 -100%;
        transition: all .5s ease-in-out;
    }
}

// VERTICAL BANNERS
.wrapper--vertical {
    display: flex;
    width: 100%;
    position: relative;  /* Posicionamento relativo para contenção dos marquees */
}

.marquee {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column; /* Configuração para rolagem vertical */
    will-change: transform;
}

.marquee__group {
    flex: 0 0 auto; /* Assegura que os elementos não se estiquem ou encolham */
}