#portfolioDetail {
    #innerBanner {
        background: #fff;
        padding-top: 60px;
        height: auto;

        &::after { display: none; }
        .container {
            justify-content: end;
            height: auto;
        }
        .breadcumbs {
            padding: 0;

            ul {  
                height: 49px;

                @media (max-width: 1100px) {
                    height: 38px;
                }
            }
        }
    }
    .detail {
        margin-block: 32px 155px;

        @media (max-width: 1100px) {
            margin-block-end: 60px;
        }
        & > .container {
            max-width: 1280px;
            padding-inline: 0;
        }
        img {
            width: 100%;
            max-width: 100%;

            &:first-child {
                border-radius: 20px 0 20px 0;
            }
        }
        h6 {
            color: $accentColor;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
        h2 {
            color: $black;
            font-size: 45px;
            font-weight: 300;
            line-height: 60px;
            padding-block: 14px 60px;

            @media (max-width: 1100px) {
                font-size: 30px;
                line-height: 40px;
                padding-block: 14px 44px;
            }
            strong { 
                color: $accentColor;
                font-weight: 600;
                font-family: inherit;
            }
        }
        p, li {
            color: $black;
            font-family: inherit;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;

            strong { 
                color: $accentColor;
                font-weight: 700;
                font-family: inherit;
            }
        }
        ul {
            margin: 0;

            li {
                color: $neutral !important;

                strong {
                    font-family: "Poppins", sans-serif;
                    color: inherit;

                    @media (max-width: 1100px) {
                        font-size: 18px;
                    }
                }
            }
            li + li { padding-top: 8px; }
        }
        figure { margin: 0; }
        table {
            &, tbody, tr, td { border: none; }
        }
        // dinamic spacing
        img + h6 { 
            padding-top: 80px;

            @media (max-width: 1100px) {
                padding-top: 44px;
            }
        }
        p + p { padding-top: 20px; }
        p + ul { 
            padding-top: 80px; 

            @media (max-width: 1100px) {
                padding-top: 44px;
            }
        }
        ul + figure, ul + div { 
            padding-top: 163px;

            @media (max-width: 1100px) {
                padding-top: 81px;
            }
        }
        // bg color
        img, h6, h2, p, ul, .container > div { background-color: #FFF6EC4D; }
        // spacing
        h6, h2, p, ul { 
            padding-inline: 227px;

            @media (max-width: 1400px) {
                padding-inline: 100px;
            }
            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
        }
        // grid
        .container > div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            img {
                border-radius: 0;

                // &:nth-child(1),
                // &:nth-child(4),
                // &:nth-child(5),
                // &:nth-child(8),
                // &:nth-child(11) {
                //     grid-column: span 2;
                // }
            }
        }
        .btn-wrapper {
            margin-top: 83px;
            
            @media (max-width: 1100px) {
                margin-top: 60px;
            }
            .button {
                @media (max-width: 1100px) {
                    width: 100%;
                    margin-inline: 25px;
                }
                h6 { 
                    color: #fff; 
                    background-color: transparent;
                    padding: 0;
                }
            }
        }
    }
    .cta-services {
        .col-lg-5 { display: none; }
        .col-lg-7 { width: 100%; }
        .title { max-width: 675px; }
        .service {
            .plus {
                width: 50px;
                border-radius: 0 0 20px 0;

                @media (max-width: 992px) {
                    display: none;
                }
                & > img {
                    max-width: 10px;
                    aspect-ratio: 1;
                }
            }
        }
    }
}