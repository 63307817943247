.footer {
    padding: 101px 0 0;

    @media (max-width: 1100px) {
        padding: 64px 0 0;

        & > .container { padding-inline: 25px; }
    }
    &--info {
        display: flex;
        flex-direction: column;
        gap: 141.5px;

        &-routes {
            max-width: 319px;

            img {
                margin-bottom: 55px;

                @media (max-width: 1100px) {
                    margin-bottom: 64px;
                }
            }
            .wrapper {
                display: flex;
                justify-content: space-between;
                gap: 20px;

                @media (max-width: 1100px) {
                    display: none;
                }
                & > div {
                    max-width: 116px;
                }
                ul {
                    width: fit-content;

                    li {
                        color: #5B5B5B;
                        font-family: inherit;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 33px;
                        transition: all .3s ease-in-out;

                        &:hover { color: #891441; }
                        a { text-decoration: none; }
                    }
                }
            }
        }
        &-contact {
            @media (max-width: 1100px) {
                display: none;
            }
            .icon-txt {
                display: flex;
                align-items: center;
                gap: 14px;

                color: #4B4B4B;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;
            }
            a + a { margin-top: 24px; }  
            a { text-decoration: none; }
        }
    }
    &--newsletter {
        h4 {
            color: #222222;
            font-size: 28px;
            font-weight: 500;
            line-height: 40px;

            @media (max-width: 1100px) {
                font-size: 30px;
            }
            strong {
                font-weight: 700;
                color: #891441;
            }
        }
        .text {
            color: #5B5B5B;
            margin-block: 13px 41px;

            @media (max-width: 1100px) {
                font-size: 14px;
                margin-block: 12px 28px;
            }
        }
        form {
            button[type="submit"] {
                margin-top: 8px;
                width: 100%;
                
                @media (max-width: 1100px) {
                    margin-top: 39px    ;
                }
                .button {
                    width: 100%;
                    height: 77px;
                }
            }
        }
        .socials { 
            justify-content: end; 
            margin-top: 106px;

            @media (max-width: 1100px) {
                justify-content: center;
            }
        }
    }
    &-bottom {
        background: #FBF6FB;
        padding: 30px 80px 24px;
        margin-top: 114px;

        @media (max-width: 1100px) {
            padding: 33px 25px 36px;
        }
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 1100px) {
                flex-direction: column;
                align-items: center;
                justify-content: start;
                gap: 20px;
            }
        }
        .rights {
            display: flex;
            align-items: center;
            gap: 16px;

            @media (max-width: 1100px) {
                flex-direction: column;
                gap: 21px;
            }
            svg {
                transition: all .5s ease-in-out;

                &:hover {
                    transform: rotate(360deg);

                    path { fill: #891441; }
                }
                path { transition: all .3s ease-in-out; }
            }
            h6 {
                color: #8D9092;
                font-size: 15px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 25px;
                    text-align: center;
                }
            }
        }
        .policy {
            h6 {
                color: #222222;
                font-size: 15px;
                font-weight: 500;
                line-height: 40px;
                transition: all .3s ease-in-out;

                &:hover { color: #891441; }
            }
        }
    }
}

.socials {
    display: flex;
    align-items: center;
    gap: 16px;
    
    img {
        transition: all .3s ease-in-out;

        &:hover {
            transform: translateY(-8px);
        }
    }
}