#blog {
    #innerBanner {
        .hero-img {
            width: Min(842px, 53vw);

            @media (max-width: 1400px) {
                width: Min(842px, 47vw);
            }
            @media (max-width: 1200px) {
                width: Min(842px, 40vw);
            }
        }
    }
    .search-result {
        color: $black;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;

        grid-column: span 2;
        margin-bottom: 44px;

        @media (max-width: 992px) {
            grid-column: span 1;
            margin-bottom: 32px;
        }
        strong {
            color: $accentColor;
            font-family: inherit;
            font-weight: 700;
        }
        .button {
            margin-top: 32px;
        }
    }
    .wrapper {
        padding: 120px 80px 152px;

        @media (min-width: 992px) {
            .col-lg-9 {
                width: 74%;
            }
            .col-lg-3 {
                width: 26%;
                padding-inline: 0;
            }
        }
        @media (max-width: 1400px) {
            padding-inline: 20px;
        }
        @media (max-width: 991px) {
            padding: 58px 25px 60px;

            .row {
                flex-direction: column-reverse;
                gap: 32px;
            }
        }
        .posts {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            padding-right: 97px;
            
            @media (max-width: 1400px) {
                place-items: center;
                padding-right: 32px;
            }
            @media (max-width: 992px) {
                grid-template-columns: 1fr;
                padding-right: 0;
            }
            .post {
                box-shadow: 0px 10.249894142150879px 27.333051681518555px 0px #00000014;
                border-radius: 20px 0 20px 0;
                
                max-width: 422px;
                min-height: 491px;
                flex-grow: 1;

                & > a {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .bg-img {
                    max-width: 100%;
                    height: 225px;

                    img { 
                        max-width: 100%;
                    }
                    .hover-img { object-fit: cover; }
                }
                .text-wrapper {
                    @media (max-width: 1100px) {
                        padding: 40px 15px 10px 20px;
                    }
                }
            }
        }
        .aside {
            @media (max-width: 991px) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                gap: 20px;
            }
            &-search {
                form {
                    @media (max-width: 991px) {
                        max-width: 54px;
                    }
                }
                .floating-label {
                    @media (max-width: 991px) {
                        max-height: 56px;
                    }
                    .form-control {
                        border: 1px solid $neutral;
                        padding-right: 60px;
                        height: 56px;

                        @media (max-width: 991px) {
                            &:not(.search-icon) {
                                width: 0;
                                visibility: hidden;
                            }
                        }
                    }
                    .form-label { 
                        color: $neutral; 
                        line-height: 25px;
                        left: -1px;
                        bottom: 9px;

                        @media (max-width: 991px) {
                            display: none;
                        }
                    }
                    .search-icon {
                        height: fit-content;
                    }
                }
            }
            &-newsletter,
            &-categories {
                background: #FBF6FB;
                border-radius: 20px 0 20px 0;
                padding: 60px 40px;
                margin-top: 32px;

                &-title {
                    color: $black;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                }
            }
            &-newsletter {
                @media (max-width: 991px) {
                    display: none;
                }
                .text { 
                    color: $neutral;
                    max-width: 228px; 
                    margin-block: 13px 22px;
                }
                .form-control {
                    height: 62px;
                }
                .form-label {
                    font-size: 12px;
                    line-height: 40px;
                    background-color: #FBF6FB;
                }
                button {
                    width: 100%;

                    .button {
                        margin-top: 8px;
                        width: 100%;
                        padding-inline: 8px;
                    }
                }
            }
            &-categories {
                @media (max-width: 991px) {
                    flex-grow: 1;
                    padding: 0;
                    margin-top: 0;
                    background: transparent;

                    &-title {
                        background: #FBF6FB;
                        border-radius: 20px 0 20px 0;
                        padding: 13px 20px;
                        position: relative;
                        max-width: 171px;
                        
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        strong { display: none; }
                        ion-icon { 
                            display: flex !important; 
                            color: $accentColor;
                            font-size: 15px;
                        }
                    }
                }
                .cat {
                    margin-top: 22px;

                    @media (max-width: 991px) {
                        position: absolute;
                        max-width: 251px;
                        padding: 20px;
                        margin-top: 0;
                        background: #FBF6FB;
                        border-radius: 0 0 20px 0;

                        display: none;
                        opacity: 0;
                        transition: opacity .5s ease-in-out;

                        &.show {
                            display: block;
                            opacity: 1;

                            & ~ .aside-categories { background: #FBF6FB !important; }
                        }
                    }
                    &-item {
                        display: block;
                        padding-block: 12px;
                        transition: all .3s ease-in-out;

                        &.active,
                        &:hover {
                            h6 { color: $accentColor; }
                        }
                        h6 {
                            color: $neutral;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 27px;                            
                        }
                    }
                }
            }
        }
        .btn-wrapper {
            width: fit-content;
            margin-inline: auto;
            margin-top: 63.8px;

            @media (max-width: 991px) {
                margin-top: 44px;
                width: Min(100%, 500px);

                .button { width: 100%; }
            }
        }
    }
}