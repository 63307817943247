.navbar {
    padding-block: 24px;
    padding-inline: 25px;
    background: #fff;

    @media (max-width: 1100px) {
        padding-block: 12px;
        padding-inline: 20px;
    }
    & > .container {
        display: flex;
        align-items: center;

        @media (max-width: 1100px) {
            padding-inline: 0;
        }
    }
    &-brand {
        img {
            width: 140px;
            height: 44.1px;

            @media (max-width: 1100px) {
                width: 71px;
                height: 59px;
            }
        }
    }
    &-toggler { 
        border: 0; 

        &:focus { box-shadow: none; }
    }
    &-nav {
        .nav-link {
            color: #5B5B5B;
            font-size: 16px;
            font-weight: 500;
            line-height: 33px;
            padding: 0 !important;
            margin: 0 16px;
            transition: all .3s ease-in-out;

            @media (max-width: 1200px) {
                margin: 0 8px;
            }
            &.active {
                color: #891441;
                font-weight: 700;
            }
            &:hover { color: #891441; }
        }
    }
    .socials {
        gap: 16px;

        img {
            max-width: 20px;
            aspect-ratio: 1;
        }
    }
    .offcanvas {
        width: Min(500px, 100%);

        @media (max-width: 992px) {
            &-header {
                padding: 25px 20px;
                justify-content: space-between;

                &--logo img {
                    width: 71px;
                    height: 59px;
                }
                &--hamburger ion-icon {
                    color: #891441;
                    font-size: 39px;
                }
            }
            &-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .navbar-nav {
                    .nav-item {
                        padding-block: 12px;

                        @media (max-height: 680px) {
                            padding-block: 0 !important;
                        }
                        .nav-link {
                            color: #222222;
                            font-family: "Poppins", sans-serif;
                            font-size: 30px;
                            font-weight: 500;
                            line-height: 40px;
                            transition: background-position .3s ease;

                            position: relative;
                            overflow: hidden;
                            display: inline-block;

                            &:not(.active) {
                                background: linear-gradient(to right, #891441, #891441 50%, #222222 50%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-size: 200% 100%;
                                background-position: 100%;
                            }
                            &.active {
                                color: #891441;
                                font-weight: 700;
                            }
                            &:hover {
                                background-position: 0 100%;
                            }
                        }
                        li + li { margin-top: 12px; }
                    }
                }
                .socials {
                    justify-content: center;
                    margin-block: 34px 40px;
                }
            }
        }
    }
}