#portfolio {
    #innerBanner {
        .content-wrapper {
            .title {
                display: none;
            }
            .text {
                color: #5b5b5b;
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                line-height: 42px;

                max-width: 83%;
                padding-top: 45px;

                @media (max-width: 1100px) {
                    max-width: 53%;
                }
                @media (max-width: 992px) {
                    max-width: 100%;
                    padding-top: 0;

                    font-size: 24px;
                    line-height: 32px;
                }
                @media (max-width: 415px) {
                    font-size: 20px;
                    line-height: 28px;
                    translate: 0 -28px;
                }
            }
        }
    }
    .portfolio {
        position: relative;
        isolation: isolate;
        margin-block: 120px;

        @media (max-width: 992px) {
            margin-block: 80px 60px;
        }
        &--grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            @media (max-width: 992px) {
                grid-template-columns: repeat(2, 1fr);
            }
            .card-item {
                @media (max-width: 992px) {
                    &:nth-child(3) {
                        order: 2;
                    }
                    &:nth-child(4) {
                        order: 3;
                    }
                }
                &:nth-child(1) {
                    grid-column: span 2;
                    grid-row: span 2;
                    height: 1003px;
    
                    @media (max-width: 992px) {
                        order: 1;
                        height: 353px;
                    }
                    & > a > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &:nth-child(3),
                &:nth-child(4) {
                    height: 758px;

                    @media (max-width: 992px) {
                        height: 326px;
                    }
                }
            }
        }
        &--info {
            background-color: #fff;
            padding: 0 115px 80px;
            width: Min(62vw, 1112px);
            grid-column: span 2;

            @media (max-width: 1350px) {
                padding-right: 80px;
            }
            @media (max-width: 992px) {
                order: 0;
                width: 100%;
                padding: 60px 25px 69px;
            }
            .title { 
                max-width: 675px;
            }
        }
        .card-item {
            & > a > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .row-2 {
            @media (max-width: 992px) {
                flex-wrap: wrap;
            }
            .card-item {
                &:nth-child(1), &:nth-child(2) {
                    height: 707px;
                    max-width: 561px;

                    @media (max-width: 992px) {
                        flex-basis: 50%;
                        max-width: 100%;
                        max-height: 245px;
                    }
                    & > a > img {
                        max-width: 561px;
                        max-height: 100%;

                        @media (max-width: 992px) {
                            max-width: 100%;
                            
                        }
                    }
                }
                &:nth-child(3) {
                    position: relative;
                    z-index: 1;
                    height: 876px;
                    min-width: 41.55vw;
                    flex-grow: 1;

                    & > a > img {
                        width: 100%;
                        object-fit: cover;
                    }
                    @media (max-width: 992px) {
                        min-width: 100%;
                        max-width: 100%;
                        height: 310px;
                        display: block;
                    }
                }
            }
        }
        .row-3 {
            margin-top: -170px;
            // max-width: 57.65vw;
            max-width: 58.5vw;

            @media (max-width: 992px) {
                margin-top: 0;
                max-width: 100%;
            }
            .card-item {
                width: 100%;
                height: 644px;

                @media (max-width: 992px) {
                    &:nth-child(1), &:nth-child(2) {
                        flex-basis: 50%;
                        height: 223px;
                    }
                }
            }
        }
        .row-4 {
            @media (max-width: 992px) {
                flex-direction: column-reverse;
            }
            .card-item {
                &:nth-child(1) {
                    width: 100%;
                    height: 750px;

                    @media (max-width: 992px) {
                        height: 432px;
                    }
                }
                &:nth-child(2) {
                    margin-top: -474px;
                    flex-grow: 1;
                    min-width: 41.55vw;
                    height: 1224px;
                    z-index: 2;

                    @media (max-width: 992px) {
                        margin-top: 0;
                        max-width: 100%;
                        min-width: 100%;
                        height: 260px;
                    }
                }
            }
        }
        .btn-wrapper {
            margin-top: 64px;

            @media (max-width: 992px) {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                padding-inline: 25px;

                .button {
                    width: Min(100%, 500px);
                }
            }
        }
    }
}
// reusable sections
.cta-services {
    background: #FBF6FB;
    padding: 109px 80px;
    overflow: hidden;

    @media (max-width: 992px) {
        padding: 60px 25px 60px;
    }
    &--title {
        margin-bottom: 61px;

        .col-lg-5 {
            display: flex;
            justify-content: end;
            align-items: end;
            padding-bottom: 35.5px;

            @media (max-width: 992px) {
                display: none;
            }
        }
    }
    &--img {
        display: flex;

        @media (max-width: 992px) {
            flex-direction: column;
            width: fit-content;
            margin-inline: auto;
        }
        & > a {
            flex-basis: 100%;
            overflow: hidden;

            &:nth-child(1) {
                border-radius: 20px 0 0 0;
            }
            &:last-child {
                border-radius: 0 0 20px 0;
            }
        }
        .service {
            position: relative;
            height: 324px;
            width: 100%;
            isolation: isolate;
            overflow: hidden;
            
            @media (max-width: 992px) {
                min-width: 253px;
                min-height: 257px;
            }
            & > img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -2;
                transition: all .3s ease-in-out;

                @media (max-width: 992px) {
                    max-width: 307px;
                }
            }
            &:hover {
                & > img { scale: 1.1; }
            }
        }
        a > img {
            max-width: 100%;
            border-radius: 20px;
        }
    }
    .button {
        width: fit-content;

        @media (max-width: 1100px) {
            display: flex !important;
            width: Min(100%, 500px);
            margin-inline: auto;
            margin-top: 60px;
        }
    }
}